<script setup lang="ts">
import { ref, onMounted } from 'vue';
import * as THREE from 'three';
import { XRSessionMode } from 'webxr';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { FontLoader, Font } from 'three/addons/loaders/FontLoader.js';
import { VRMesh, GlauCUTUStimulusGlowingTest, XRGamepad, RIGHT_MASK, LEFT_MASK, TestPoint } from '../utils/glaucutu'
import { Vector2 } from 'three';

import WebRTC from './WebRTC.vue';
import { Socket } from 'socket.io-client';
import GlauCUTUResult from './GlauCUTUResult.vue';

const supportVR = ref(true)
const haveTest = ref(false)

const experience = ref<HTMLCanvasElement | null>(null);
const experience2 = ref<HTMLCanvasElement | null>(null);
// const audio = ref<HTMLAudioElement | null>(null);
const fov = 30;

const width = window.innerWidth - 20
const height = window.innerHeight - 50
const aspectRatio = width / height
let renderer: THREE.WebGLRenderer
let renderer2: THREE.WebGLRenderer

const BG_GRAY_LEVEL = 30/255.0

const fontColor = 0xFFFFFF;
const fixationColor = 0x00FF00;

THREE.ColorManagement.legacyMode = false;

var scene = new THREE.Scene();
const bgColor = new THREE.Color(BG_GRAY_LEVEL, BG_GRAY_LEVEL, BG_GRAY_LEVEL)
scene.background = bgColor;

const camera = new THREE.PerspectiveCamera(fov, aspectRatio);
camera.position.set(0,0,0)
camera.lookAt(new THREE.Vector3(0,0,-1))
scene.add(camera)

const spectatorL = new THREE.PerspectiveCamera(60, 1);
spectatorL.position.set(0,0,0)
spectatorL.lookAt(new THREE.Vector3(0,0,-1))
scene.add(spectatorL)

spectatorL.layers.enable(0);
spectatorL.layers.enable(LEFT_MASK >> 1)

const spectatorR = new THREE.PerspectiveCamera(60, 1);
spectatorR.position.set(0,0,0)
spectatorR.lookAt(new THREE.Vector3(0,0,-1))
scene.add(spectatorR)

spectatorR.layers.enable(0);
spectatorR.layers.enable(RIGHT_MASK >> 1)

const sp_width = 640
const sp_height = 640

const loader = new FontLoader();
let text: THREE.Mesh
loader.load( 'fonts/NotoSansThai_Regular.json', function ( font:Font ) {
  const matDark = new THREE.MeshBasicMaterial( {
    color: fontColor,
    side: THREE.DoubleSide
  } );

  const message = 'มองที่จุดเขียว\n ตลอดเวลา';
  const shapes = font.generateShapes( message, 0.1 );
  const geometry = new THREE.ShapeGeometry( shapes );

  geometry.computeBoundingBox();
  const xMid = (geometry.boundingBox)?-0.5*(geometry.boundingBox.max.x - geometry.boundingBox.min.x):0;
  geometry.translate( xMid, 0.4, 0 );

  text = new THREE.Mesh( geometry, matDark );
	text.position.z = -5;
	scene.add( text );
})

const fixationPoint = new VRMesh(
  new THREE.OctahedronGeometry( 0.025, 0 ), // 5 mm.
  fixationColor,
  new THREE.Vector2(0,0),
  5
)
scene.add(fixationPoint);

const blindPointR = new VRMesh(
  new THREE.SphereGeometry( 0.0125, 36, 36 ), // 1 mm.
  0xFF0000,
  new THREE.Vector2(15.5,-1.5),
  5
)
blindPointR.layers.set(RIGHT_MASK >> 1)
scene.add(blindPointR);

const blindPointL = new VRMesh(
  new THREE.SphereGeometry( 0.0125, 36, 36 ), // 1 mm.
  0xFF0000,
  new THREE.Vector2(-15.5,-1.5),
  5
)
blindPointL.layers.set(LEFT_MASK >> 1)
scene.add(blindPointL);

const tester = new GlauCUTUStimulusGlowingTest(scene, fixationPoint, BG_GRAY_LEVEL)

let socket: Socket
const webRTCConnection = ref<typeof WebRTC | null>(null)
const glacutuResult = ref<typeof GlauCUTUResult | null>(null)

async function exitXR( renderer: THREE.WebGLRenderer ) {
  const session = renderer.xr.getSession();
  if ( session !== null ) {
    await session.end();
  }
}

function enterVR(){
  const sessionInit = { optionalFeatures: [ 'local-floor', 'bounded-floor' ] };
  if ("xr" in window.navigator && window.navigator.xr != undefined){
    window.navigator.xr.requestSession( "immersive-vr", sessionInit ).then( async (session)=>{
      await renderer.xr.setSession( session );
    });
  }
}

var render2ID = -1
const onVRStart = () => {
  // console.log("VR Session Start")
  camera.lookAt(new THREE.Vector3(0,0,-1))
  setTimeout(()=>{
    const cameras = renderer.xr.getCamera().cameras
    cameras[0].layers.mask = LEFT_MASK
    cameras[1].layers.mask = RIGHT_MASK
  }, 200)
  
  socket.volatile.emit("vrevent", {
    event: "vrstart",
  })

  render2ID = setInterval(onRender2, 100); // 10Hz
}

const onVREnd = () => {
  // console.log("VR Session End")
  camera.lookAt(new THREE.Vector3(0,0,-1))
  tester.stop()
  socket.volatile.emit("vrevent", {
    event: "vrend",
  })
  clearInterval(render2ID)
}

const onRender = () => {
  renderer.render( scene, camera );
}

const onRender2 = () => {
  renderer2.setViewport( 0, 0, sp_width, sp_height );
  renderer2.setScissorTest(false);
  renderer2.render( scene, spectatorL );
  renderer2.setViewport( sp_width, 0,  sp_width, sp_height );
  renderer2.setScissor(sp_width, 0, sp_width, sp_height);
  renderer2.setScissorTest(true);
  renderer2.setClearColor(bgColor);
  renderer2.render( scene, spectatorR );
}

tester.onStartTest = () => {
  haveTest.value = true
  text.visible = false
  blindPointL.visible = false
  blindPointR.visible = false
  
  // audio.value?.play()

  socket.volatile.emit("vrevent", {
    event: "starttest",
  })
}

tester.onStopTest = () => {
  if (glacutuResult.value == undefined){
    return
  }
  text.visible = true
  blindPointL.visible = true
  blindPointR.visible = true

  fixationPoint.moveTo(new Vector2(0,0))
  // audio.value?.pause()

  const results = tester.getResults()
  // console.log(results)

  for(const g in results['responses']) {
    let val = results['responses'][g]['avg'];
    if(val < 0){
      glacutuResult.value.vfvalues[g] = -1;
      glacutuResult.value.vfclass[g] = "";
      continue;
    }
    
    val = Math.round(100 - ((val/1.2)*100)) // maximum duration = 1.2 seconds
    if (val < 0) {
      val = 0
    }

    else if(val >= 50){
      glacutuResult.value.vfvalues[g] = val;
      glacutuResult.value.vfclass[g] = "normal";
    }else if (val >= 15){
      glacutuResult.value.vfvalues[g] = val;
      glacutuResult.value.vfclass[g] = "warning";
    }else {
      glacutuResult.value.vfvalues[g] = val;
      glacutuResult.value.vfclass[g] = "danger";
    }
  }
  const elapsedTime = tester.getElapsedTime()
  console.log("Total test time: ", elapsedTime)
  
  document.getElementById("glaucuturesult")?.scrollIntoView({
      behavior: 'smooth', // smooth scroll
      block: 'start' // the upper border of the element will be aligned at the top of the visible part of the window of the scrollable area.
    })

  socket.volatile.emit("vrevent", {
    event: "stoptest",
    tester: tester.tests,
    totaltime: elapsedTime,
    result: results,
  })

  exitXR(renderer)
  renderer.clear()
  experience.value?.parentNode?.removeChild(experience.value)
}

tester.onShowTest = (test: TestPoint) => {
  // console.log("Show Test", test)
  socket.volatile.emit("vrevent", {
    event: "showtest",
    test: test,
  })
}

tester.onMiss = (test: TestPoint) => {
  // console.log("Missed", test)
  socket.volatile.emit("vrevent", {
    event: "missed",
    test: test,
  })
}

tester.onHit = (test: TestPoint) => {
  // console.log("onHit", test)
  socket.volatile.emit("vrevent", {
    event: "hit",
    test: test,
  })
}
tester.onFalseHit = () => {
  console.log("onFalseHit")
  socket.volatile.emit("vrevent", {
    event: "falsehit",
  })
}

if ( 'xr' in window.navigator && window.navigator.xr != undefined ) {
  window.navigator.xr.isSessionSupported("immersive-vr").then( function ( supported ) {
    supportVR.value = supported
  })
  window.navigator.xr.addEventListener( 'sessiongranted', () => {
    // supportVR.value = true
    console.log('sessiongranted')
  });
} else {
  supportVR.value = false
}

onMounted(() => {
  renderer = new THREE.WebGLRenderer({
    canvas: experience.value as HTMLCanvasElement,
    antialias: true,
  })
  renderer.setPixelRatio(2)
  renderer.setAnimationLoop(onRender);

  renderer2 = new THREE.WebGLRenderer({
    canvas: experience2.value as HTMLCanvasElement,
    antialias: true,
  })
  renderer2.setAnimationLoop(onRender2);

  // Enable WebXR
  renderer.xr.enabled = true;
  renderer.xr.setFramebufferScaleFactor(2.0)
  renderer.xr.setReferenceSpaceType("viewer") 
  // renderer.xr.cameraAutoUpdate = true  
  renderer.xr.addEventListener("sessionstart", onVRStart)
  renderer.xr.addEventListener("sessionend", onVREnd)
  
  const gamepads = [new XRGamepad(renderer.xr.getController(0)), new XRGamepad(renderer.xr.getController(1))]

  for(const g of gamepads){
    g.addEventListener('gamepadbt3pressed', ()=>{
      if(tester.isStarted){
        tester.stop()
      }else{
        tester.start()
      }
    }) // Axis
    for(const ev of ['gamepadbt0down', 'gamepadbt4down', 'gamepadbt5down']){
      g.addEventListener(ev, ()=>{
        tester.response()
      })
    }
    for(const ev of ['gamepadbt0up', 'gamepadbt4up', 'gamepadbt5up']){ // select, A, B button
      g.addEventListener(ev, ()=>{
        tester.next()
      })
    }
  }

  if (webRTCConnection.value != undefined && experience2.value != undefined){
    webRTCConnection.value.setCanvasReference(experience2.value)
    socket = webRTCConnection.value.connectWebSocket()
    socket.on("vrevent", (arg)=>{
      switch (arg['event']) {
        case 'entervr':
          enterVR();
          break;
        case 'exitvr':
          exitXR(renderer);
          break;
        case 'stoptest':
          if (renderer.xr.isPresenting){
            tester.stop();
          }
          break;
        case 'starttest':
          if (renderer.xr.isPresenting){
            tester.start();
          }
          break
        default:
          // console.log("vrevent", arg)
          break;
      }
    })
  }
});
</script>

<template>
  <div v-if="supportVR" class="row justify-content-between mb-4 rounded-3 content technician-mode">
    <div class="col-md content-dark p-4 rounded">
      <WebRTC ref="webRTCConnection"></WebRTC>
    </div>
    <div class="col-md content rounded-end relative p-4 bg-vr-head">
      <canvas ref="experience" height="0" width="0"></canvas>
      <!-- <audio ref="audio" loop="true" preload="auto" rel="preload"><source src="sounds/pink_noise.mp3" /></audio> -->
      
      <h3><strong>ทดสอบเบื้องต้นด้วยตนเอง</strong></h3>
      <div class="p-3 detail pt-3 rounded-3">
        ทดสอบลานสายตาด้วยตนเองเบื้องต้น กรุณาอ่านขั้นตอนการทดสอบให้เข้าใจก่อนเริ่มการทดสอบ 
        การทดสอบนี้ใช้เวลาประมาณ 5-8 นาที ขึ้นอยู่กับประสิทธิภาพการตอบสนองของแต่ละคน
      </div>
      <button type="button" class="btn btn-danger btn-lg mt-5 mb-5" style="background: darkred" @click="enterVR">เข้าสู่โหมด VR</button>
      <img id="vr" src="img/vr-head.png" width=200 class="img-center-bottom">
      
      <canvas ref="experience2" id="spectation" width="1280" height="640"></canvas>
              <!-- -->
    </div>
  </div>
  <div v-else  class="row justify-content-between mb-4 rounded-3 content technician-mode">
    <div class="mt-3 p-3 rounded-3 mb-3">
      อุปกรณ์นี้ไม่รอบรับ VR กรุณาใช้อุปกรณ์ที่รองรับเพื่อเข้าทดสอบ
    </div> 
  </div>
  <div id="glaucuturesult" v-if="haveTest" class="row rounded-3 content p-3">
    <div class="col-md-8">
      <h3>ผลการทดสอบเบื้องต้น</h3>
      <GlauCUTUResult :width=1000 :height=480 ref="glacutuResult"></GlauCUTUResult>
    </div>
    <div class="col-md-4">
      <h4>ช่วงคะแนน</h4>
      <div><strong>50-100</strong>: ตอบสนองปกติ</div>
      <div><strong>15-50</strong>: ตอบสนองช้ากว่าปกติ</div>
      <div><strong>ต่ำกว่า 15</strong>: ตอบสนองผิดปกติ</div>
      <div><strong>ไม่มีคะแนน/ต่ำกว่า 0</strong>: ไม่ตอบสนอง ณ บริเวณนั้น</div>
      
      <div class="mt-3">เป็นผลการทดสอบเบื้องต้นเท่านั้น หากท่านทดสอบซ้ำและยังพบบริเวณที่<strong>ตอบสนองผิดปกติหรือไม่ตอบสนอง</strong> และมีการอาการดังต่อไปนี้ร่วมด้วย ได้แก่
        <ul>
          <li>ปวดตา</li>
          <li>น้ำตาไหล</li>
          <li>ตามัวลง</li>
          <li>เห็นรุ้งรอบดวงไฟ</li>
          <li>เดินชนโต๊ะ/ตู้ ถี่ในช่วงนี้</li>
        </ul>
        ควรปรึกษาจักษุแพทย์เพื่อทำการตรวจคัดกรองโรคต้อหินต่อไป
      </div>
    </div>
  </div>
</template>

<style scoped>
#spectation {
  display: none;
}
</style>