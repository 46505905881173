<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="3 89 500 240" :width="props.width" :height="props.height" class="img-fluid svg">
    <defs></defs>
    <rect x="6" y="90" width="490" height="238" rx="10" ry="10" style="stroke: rgb(0, 0, 0); fill: rgb(255, 255, 255); pointer-events: none;">
        <title>Background</title>
    </rect>
    <text style="fill: rgb(51, 51, 51); font-family: Kanit; font-size: 12px; white-space: pre; pointer-events: none;" x="235.912" y="203.288"><title>Nose Landmark</title>Nose</text>
    <g style="">
        <title>Left Eye</title>
        <path :class=vfclass.l31 d="M 121.937 100 C 176.937 100 222.053 144.818 221.937 200 L 121.937 200 L 121.937 100 Z" id="fl31"></path>
        <path :class=vfclass.l32 d="M 21.937 200 C 76.937 200 122.053 155.182 121.937 100 L 21.937 100 L 21.937 200 Z" transform="matrix(-1, 0, 0, -1, 143.874219, 299.999992)" id="fl32"></path>
        <path :class=vfclass.l33 d="M 121.937 300 C 176.937 300 222.053 255.182 221.937 200 L 121.937 200 L 121.937 300 Z" id="fl33"></path>
        <path :class=vfclass.l34 d="M 21.937 200 C 76.937 200 122.053 244.818 121.937 300 L 21.937 300 L 21.937 200 Z" transform="matrix(-1, 0, 0, -1, 143.874219, 499.999997)" id="fl34"></path>
        <path :class=vfclass.l21 d="M 121.937 130 C 160.437 130 192.019 161.372 191.937 200 L 121.937 200 L 121.937 130 Z" id="fl21"></path>
        <path :class=vfclass.l22 d="M 51.937 200 C 90.437 200 122.019 168.628 121.937 130 L 51.937 130 L 51.937 200 Z" transform="matrix(-1, 0, 0, -1, 173.87415, 329.999987)" id="fl22"></path>
        <path :class=vfclass.l23 d="M 121.937 270 C 160.437 270 192.019 238.628 191.937 200 L 121.937 200 L 121.937 270 Z" id="fl23"></path>
        <path :class=vfclass.l24 d="M 51.937 200 C 90.437 200 122.019 231.372 121.937 270 L 51.937 270 L 51.937 200 Z" transform="matrix(-1, 0, 0, -1, 173.87415, 470.000003)" id="fl24"></path>
        <path :class=vfclass.l11 d="M 121.937 160 C 143.937 160 161.984 177.927 161.937 200 L 121.937 200 L 121.937 160 Z" id="fl11"></path>
        <path :class=vfclass.l12 d="M 81.937 200 C 103.937 200 121.984 182.073 121.937 160 L 81.937 160 L 81.937 200 Z" transform="matrix(-1, 0, 0, -1, 203.874097, 360.000013)" id="fl12"></path>
        <path :class=vfclass.l13 d="M 121.937 240 C 143.937 240 161.984 222.073 161.937 200 L 121.937 200 L 121.937 240 Z" id="fl13"></path>
        <path :class=vfclass.l14 d="M 81.937 200 C 103.937 200 121.984 217.927 121.937 240 L 81.937 240 L 81.937 200 Z" transform="matrix(-1, 0, 0, -1, 203.874097, 440.000008)" id="fl14"></path>
        <text style="fill: rgb(51, 51, 51); font-family: Kanit; font-size: 10px; stroke-opacity: 0.5; text-anchor: middle; white-space: pre;" x="121.792" y="314.468">Left Eye</text>
    </g>
    <g style="">
        <title>Right Eye</title>
        <path :class=vfclass.r31 d="M 279.548 200 C 334.548 200 379.664 155.182 379.548 100 L 279.548 100 L 279.548 200 Z" transform="matrix(-1, 0, 0, -1, 659.096201, 299.999992)" id="fr31"></path>
        <path :class=vfclass.r32 d="M 379.548 100 C 434.548 100 479.664 144.818 479.548 200 L 379.548 200 L 379.548 100 Z" id="fr32"></path>
        <path :class=vfclass.r33 d="M 279.548 200 C 334.548 200 379.664 244.818 379.548 300 L 279.548 300 L 279.548 200 Z" transform="matrix(-1, 0, 0, -1, 659.096201, 499.999997)" id="fr33"></path>
        <path :class=vfclass.r34 d="M 379.548 300 C 434.548 300 479.664 255.182 479.548 200 L 379.548 200 L 379.548 300 Z" id="fr34"></path>
        <path :class=vfclass.r21 d="M 309.548 200 C 348.048 200 379.63 168.628 379.548 130 L 309.548 130 L 309.548 200 Z" transform="matrix(-1, 0, 0, -1, 689.096132, 329.999987)" id="fr21"></path>
        <path :class=vfclass.r22 d="M 379.548 130 C 418.048 130 449.63 161.372 449.548 200 L 379.548 200 L 379.548 130 Z" id="fr22"></path>
        <path :class=vfclass.r23 d="M 309.548 200 C 348.048 200 379.63 231.372 379.548 270 L 309.548 270 L 309.548 200 Z" transform="matrix(-1, 0, 0, -1, 689.096132, 470.000003)" id="fr23"></path>
        <path :class=vfclass.r24 d="M 379.548 270 C 418.048 270 449.63 238.628 449.548 200 L 379.548 200 L 379.548 270 Z" id="fr24"></path>
        <path :class=vfclass.r11 d="M 339.548 200 C 361.548 200 379.595 182.073 379.548 160 L 339.548 160 L 339.548 200 Z" transform="matrix(-1, 0, 0, -1, 719.096126, 360.000013)" id="fr11"></path>
        <path :class=vfclass.r12 d="M 379.548 160 C 401.548 160 419.595 177.927 419.548 200 L 379.548 200 L 379.548 160 Z" id="fr12"></path>
        <path :class=vfclass.r13 d="M 339.548 200 C 361.548 200 379.595 217.927 379.548 240 L 339.548 240 L 339.548 200 Z" transform="matrix(-1, 0, 0, -1, 719.096126, 440.000008)" id="fr13"></path>
        <path :class=vfclass.r14 d="M 379.548 240 C 401.548 240 419.595 222.073 419.548 200 L 379.548 200 L 379.548 240 Z" id="fr14"></path>
        <text style="fill: rgb(51, 51, 51); font-family: Kanit; font-size: 10px; stroke-opacity: 0.5; text-anchor: middle; white-space: pre;" x="378.977" y="314.468">Right Eye</text>
    </g>
    <g>
        <title>Left Eye Text</title>
        <text class="text" :class=vfclass.l11 x="138.991" y="187.242" id="l11">{{ format_number(vfvalues.l11) }}</text>
        <text class="text" :class=vfclass.l12 x="104.511" y="187.242" id="l12">{{ format_number(vfvalues.l12) }}</text>
        <text class="text" :class=vfclass.l13 x="138.991" y="220.187" id="l13">{{ format_number(vfvalues.l13) }}</text>
        <text class="text" :class=vfclass.l14 x="104.511" y="220.187" id="l14">{{ format_number(vfvalues.l14) }}</text>
        <text class="text" :class=vfclass.l21 x="163.249" y="169.196" id="l21">{{ format_number(vfvalues.l21) }}</text>
        <text class="text" :class=vfclass.l22 x="79.253" y="169.196" id="l22">{{ format_number(vfvalues.l22) }}</text>
        <text class="text" :class=vfclass.l23 x="163.249" y="239.763" id="l23">{{ format_number(vfvalues.l23) }}</text>
        <text class="text" :class=vfclass.l24 x="77.253" y="238.233" id="l24">{{ format_number(vfvalues.l24) }}</text>
        <text class="text" :class=vfclass.l31 x="189.371" y="151.15" id="l31">{{ format_number(vfvalues.l31) }}</text>
        <text class="text" :class=vfclass.l32 x="53.995" y="151.15" id="l32">{{ format_number(vfvalues.l32) }}</text>
        <text class="text" :class=vfclass.l33 x="189.371" y="256.809" id="l33">{{ format_number(vfvalues.l33) }}</text>
        <text class="text" :class=vfclass.l34 x="53.995" y="256.809" id="l34">{{ format_number(vfvalues.l34) }}</text>
    </g>
    <g>
        <title>Right Eye Text</title>
        <text class="text" :class=vfclass.r11 x="363.145" y="187.242" id="r11">{{ format_number(vfvalues.r11) }}</text>
        <text class="text" :class=vfclass.r12 x="397.625" y="187.242" id="r12">{{ format_number(vfvalues.r12) }}</text>
        <text class="text" :class=vfclass.r13 x="363.145" y="220.187" id="r13">{{ format_number(vfvalues.r13) }}</text>
        <text class="text" :class=vfclass.r14 x="397.625" y="220.187" id="r14">{{ format_number(vfvalues.r14) }}</text>
        <text class="text" :class=vfclass.r21 x="337.887" y="169.196" id="r21">{{ format_number(vfvalues.r21) }}</text>
        <text class="text" :class=vfclass.r22 x="421.883" y="169.196" id="r22">{{ format_number(vfvalues.r22) }}</text>
        <text class="text" :class=vfclass.r23 x="335.887" y="238.233" id="r23">{{ format_number(vfvalues.r23) }}</text>
        <text class="text" :class=vfclass.r24 x="421.883" y="239.763" id="r24">{{ format_number(vfvalues.r24) }}</text>
        <text class="text" :class=vfclass.r31 x="312.629" y="151.15" id="r31">{{ format_number(vfvalues.r31) }}</text>
        <text class="text" :class=vfclass.r32 x="448.005" y="151.15" id="r32">{{ format_number(vfvalues.r32) }}</text>
        <text class="text" :class=vfclass.r33 x="312.629" y="256.809" id="r33">{{ format_number(vfvalues.r33) }}</text>
        <text class="text" :class=vfclass.r34 x="448.005" y="256.809" id="r34">{{ format_number(vfvalues.r34) }}</text>
    </g>
    </svg>
</template>

<script setup lang="ts">
import { defineProps, withDefaults, ref, defineExpose } from 'vue';

interface Props {
    width?: number,
    height?: number,
}

const vfvalues = ref({
    l11: -1,
    l12: -1,
    l13: -1,
    l14: -1,
    l21: -1,
    l22: -1,
    l23: -1,
    l24: -1,
    l31: -1,
    l32: -1,
    l33: -1,
    l34: -1,
    r11: -1,
    r12: -1,
    r13: -1,
    r14: -1,
    r21: -1,
    r22: -1,
    r23: -1,
    r24: -1,
    r31: -1,
    r32: -1,
    r33: -1,
    r34: -1,
})

const vfclass = ref({
    l11: '',
    l12: '',
    l13: '',
    l14: '',
    l21: '',
    l22: '',
    l23: '',
    l24: '',
    l31: '',
    l32: '',
    l33: '',
    l34: '',
    r11: '',
    r12: '',
    r13: '',
    r14: '',
    r21: '',
    r22: '',
    r23: '',
    r24: '',
    r31: '',
    r32: '',
    r33: '',
    r34: '',
})

const props = withDefaults(defineProps<Props>(),{
  width: 1000,
  height: 480,
})

defineExpose({
    vfclass,
    vfvalues
})

const format_number = (value:number)=>{
   return value.toLocaleString()
}
</script>

<style>
.svg path {
    fill: rgb(20, 20, 20);
    stroke: rgb(0, 0, 0); 
    stroke-opacity: 0.5;
}
.svg .text{
    fill: rgb(100, 100, 100);
    font-size: 12px;
    text-anchor: middle;
    white-space: pre;
}

.svg .normal {
    fill: rgb(0, 255, 0)
}
.svg .text.normal {
    fill: rgb(255, 255, 255)
}


.svg .warning {
    fill: rgb(255, 120, 0)
}
.svg text.warning {
    fill: rgb(255, 255, 255)
}

.svg .danger {
    fill: rgb(255, 0, 0)
}
.svg text.danger {
    fill: rgb(255, 255, 255)
}

</style>