<template>
    <VRGlauCUTU/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VRGlauCUTU from '@/components/VRGlauCUTU.vue'; // @ is an alias to /src

export default defineComponent({
  name: 'HomeView',
  components: {
    VRGlauCUTU,
  },
});
</script>
