import * as THREE from 'three';
import { Vector2 } from 'three';

class XRGamepad extends THREE.EventDispatcher {
    private controller: THREE.XRTargetRaySpace
    private inputsource: XRInputSource | undefined
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    private dispatcherID: number
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    private gamepad: Gamepad | undefined
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    private gamepadbuttoncache: boolean[]
  
    constructor(controller: THREE.XRTargetRaySpace) {
      super()
      this.controller = controller
      this.controller.addEventListener('connected', (event)=> {
          this.inputsource = event.data
          this.gamepad = this.inputsource? this.inputsource.gamepad: undefined;
          const AXES_THRESHOLD = 0.5
          
          console.log("Gamepad C`onnected")
          this.dispatchEvent({
            type: `gamepadconnected`, 
          })
          this.dispatcherID = setInterval(()=>{
            if (this.gamepad){
              for(const axis of this.gamepad.axes){
                if (Math.abs(axis) >= AXES_THRESHOLD) {
                  this.dispatchEvent({type: "gamepadaxismove", data: this.gamepad.axes})
                  break
                }
              }
  
              if(this.gamepadbuttoncache){
                const timestamp = this.gamepad.timestamp
                for(const [i,button] of this.gamepad.buttons.entries()){
                  if (button.pressed && !this.gamepadbuttoncache[i]){
                    this.dispatchEvent({
                      type: `gamepadbt${i}down`, 
                      data: {
                        touched: button.touched,
                        value: button.value,
                        timestamp
                      },
                    })
                  } else if (!button.pressed && this.gamepadbuttoncache[i]){
                    this.dispatchEvent({
                      type: `gamepadbt${i}up`, 
                      data: {
                        value: button.value,
                        timestamp
                      },
                    })
                    this.dispatchEvent({
                      type: `gamepadbt${i}pressed`, 
                      data: {
                        value: button.value,
                        timestamp
                      },
                    })
                  }
                }
                this.gamepadbuttoncache = this.gamepad.buttons.map(x=>x.pressed)
              }else{
                this.gamepadbuttoncache = this.gamepad.buttons.map(x=>x.pressed)
              }
            }
          }, 10) // 100 Hz
      });
      this.controller.addEventListener('disconnected', ()=>{
        this.dispatchEvent({
            type: `gamepaddisconnected`, 
          })
        console.log("Gamepad Diconnected")
        clearInterval(this.dispatcherID)
      })
    }
}

class VRMesh extends THREE.Mesh {
    public position_angle: THREE.Vector2;
    private distance: number;
    private color: THREE.Color;
  
    constructor(geometry: THREE.SphereGeometry | THREE.OctahedronGeometry, color: THREE.Color | number, position_angle: THREE.Vector2, distance: number){
      super(geometry, new THREE.MeshBasicMaterial({color}))
      this.color = new THREE.Color(color)
      this.position_angle = position_angle
      this.distance = distance
      this.updatePosition()
    }
  
    public moveTo(position_angle: THREE.Vector2) {
      this.position_angle = position_angle
      this.updatePosition()
    }
  
    public updatePosition(){
      const DEC2RAD = Math.PI / 180.0
  
      this.position.x = this.distance * Math.sin(this.position_angle.x * DEC2RAD)
      this.position.y = this.distance * Math.sin(this.position_angle.y * DEC2RAD)
      this.position.z = -Math.sqrt(this.distance**2 - this.position.x**2 - this.position.y**2)
    }
  
    public setColor(color: number){
      this.color.set(color)
      this.updateColor()
    }
  
    public setGrayColor(gray: number){
      this.color.setRGB(gray, gray, gray)
      this.updateColor()
    }
  
    public updateColor(){
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.material.color.set(this.color)
    }
}

class StimulusGlowingMesh extends VRMesh {
    private baseGray: number
    private maxGray: number
    private startTime: number
    private ratio: number
    private mask: number
    private misscb: (()=>void)|undefined
    private waittime: number
    private clock: THREE.Clock

    constructor(position_angle: Vector2, distance: number, size: number, baseGray: number, maxGray: number, duration: number, delay: number, mask: number, misscb: (()=>void)|undefined){
        super(
            new THREE.SphereGeometry( size, 36, 36 ), 
            new THREE.Color(baseGray, baseGray, baseGray), 
            position_angle,
            distance
        )
        this.baseGray = baseGray
        this.maxGray = maxGray
        this.ratio = (this.maxGray - this.baseGray)/duration
        this.startTime = Infinity // set to idle state
        this.mask = mask >> 1
        this.layers.set(this.mask)

        this.waittime = duration + delay
        this.misscb = misscb
        this.clock = new THREE.Clock()
        this.clock.autoStart = false
        this.clock.stop()

        // this.onBeforeRender = (renderer: THREE.WebGLRenderer, scene: THREE.Scene, camera: THREE.Camera, geometry: THREE.BufferGeometry, material: THREE.Material, group: THREE.Group) => {
        this.onBeforeRender = () => {
            try {
                if (this.clock.running) {
                    const current_time = this.clock.getElapsedTime()*1000
                    if (current_time >= this.waittime){
                        this.clock.stop()
                        this.setGrayColor(this.baseGray) // hidden
                        if(this.misscb){
                            this.misscb()
                        }
                        return
                    }
                    const gray = this.baseGray + current_time*this.ratio
                    this.setGrayColor(gray)
                } else {
                    this.setGrayColor(this.baseGray)
                }
            } catch (error) {
                console.error(error)
            }
        }
    }

    public start(){
        this.clock.start()
    }

    public stop(){
        this.clock.stop()
    }
}

// Nasel is positive
const TestList24_2 = [
    new Vector2(-3,3),
    new Vector2(-9,3),
    new Vector2(-15,3),
    new Vector2(-21,3),
    new Vector2(3,3),
    new Vector2(9,3),
    new Vector2(15,3),
    new Vector2(21,3),
    new Vector2(27,3),

    new Vector2(-3,-3),
    new Vector2(-9,-3),
    new Vector2(-15,-3),
    new Vector2(-21,-3),
    new Vector2(3,-3),
    new Vector2(9,-3),
    new Vector2(15,-3),
    new Vector2(21,-3),
    new Vector2(27,-3),

    new Vector2(3,9),
    new Vector2(9,9),
    new Vector2(15,9),
    new Vector2(21,9),
    new Vector2(-3,9),
    new Vector2(-9,9),
    new Vector2(-15,9),
    new Vector2(-21,9),

    new Vector2(3,-9),
    new Vector2(9,-9),
    new Vector2(15,-9),
    new Vector2(21,-9),
    new Vector2(-3,-9),
    new Vector2(-9,-9),
    new Vector2(-15,-9),
    new Vector2(-21,-9),
    
    new Vector2(3,15),
    new Vector2(9,15),
    new Vector2(15,15),
    new Vector2(-3,15),
    new Vector2(-9,15),
    new Vector2(-15,15),

    new Vector2(3,-15),
    new Vector2(9,-15),
    new Vector2(15,-15),
    new Vector2(-3,-15),
    new Vector2(-9,-15),
    new Vector2(-15,-15),

    new Vector2(3,21),
    new Vector2(9,21),
    new Vector2(-9,21),
    new Vector2(-3,21),

    new Vector2(3,-21),
    new Vector2(9,-21),
    new Vector2(-9,-21),
    new Vector2(-3,-21),

    new Vector2(-18,-6),
    new Vector2(-18,0),
    new Vector2(-18,6),

    new Vector2(-12,-6),
    new Vector2(-12,0),
    new Vector2(-12,6),

    new Vector2(24,-6),
    new Vector2(30,-6),
    new Vector2(24,6),
    new Vector2(30,6),
]

class TestPoint {
    public name: string;
    public point_angle: Vector2;
    public ref_point_angle: Vector2;
    public mask: number;
    public size: number;
    public fixation_x: number;
    public reactiontime = -1; // missed by default
    public zone: number;
    public quadrant: number;
    public group: string;
    static SIZES = [0.015, 0.0200, 0.0250]

    constructor(name: string, point_angle: Vector2, side: string, fixation_x: number){
        this.name = name
        this.ref_point_angle = point_angle;
        if (side == "l") {
            this.point_angle = new Vector2(point_angle.x + fixation_x, point_angle.y);
            this.mask = LEFT_MASK;
        }else if (side=="r") {
            this.point_angle = new Vector2(-point_angle.x + fixation_x, point_angle.y);
            this.mask = RIGHT_MASK;
        }else{
            this.point_angle = new Vector2(-point_angle.x + fixation_x, point_angle.y);
            this.mask = 0;
        }
        this.fixation_x = fixation_x

        if (Math.abs(point_angle.x) + Math.abs(point_angle.y) <= 15){
            this.size = TestPoint.SIZES[0]
            this.zone = 1
        } else if(Math.abs(point_angle.x) + Math.abs(point_angle.y) <= 18) {
            this.size = TestPoint.SIZES[1]
            this.zone = 2
        } else {
            this.size = TestPoint.SIZES[2]
            this.zone = 3
        }

        // this.zone = Math.max(Math.ceil(Math.abs(this.ref_point_angle.x)/13),Math.ceil((this.ref_point_angle.y)/10))
        if (this.ref_point_angle.x < 0){
            if (this.ref_point_angle.y < 0){
                this.quadrant = 3
            }else{
                this.quadrant = 1
            }
        } else {
            if (this.ref_point_angle.y < 0){
                this.quadrant = 4
            }else{
                this.quadrant = 2
            }
        }
        this.group = side + String(this.zone) + String(this.quadrant)
    }

    static random_sort(a: TestPoint, b: TestPoint){
        if (a.fixation_x == b.fixation_x){
            return 0.5-Math.random()
        } else if (a.fixation_x == 0) {
            return -1
        } else if (b.fixation_x == 0) {
            return 1
        } else{
            return a.fixation_x - b.fixation_x
        }
    }
}

const LEFT_MASK = 3;
const RIGHT_MASK = 5;

const asc = (arr:number[]) => arr.sort((a: number, b: number) => a - b);

const sum = (arr:number[]) => arr.reduce((a: number, b: number) => a + b, 0);

const mean = (arr:number[]) => sum(arr) / arr.length;

// sample standard deviation
const std = (arr:number[]) => {
    const mu = mean(arr);
    const diffArr = arr.map(a => (a - mu) ** 2);
    return Math.sqrt(sum(diffArr) / (arr.length - 1));
};

const quantile = (arr:number[], q:number) => {
    const sorted = asc(arr);
    const pos = (sorted.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;
    if (sorted[base + 1] !== undefined) {
        return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
    } else {
        return sorted[base];
    }
};

const iqr = (arr: number[]) => {
    const q1 = quantile(arr, .25)
    const q3 = quantile(arr, .75)
    const iqr = q1-q3
    return {
        'lower_bound': q1 - 1.5 * iqr,
        'uppper_bound': q3 + 1.5 * iqr,
        'iqr': iqr,
        'q1': q1,
        'q3': q3,
    }
}

const basicGroup = [
    'l11','l12','l13','l14',
    'l21','l22','l23','l24',
    'l31','l32','l33','l34',
    'r11','r12','r13','r14',
    'r21','r22','r23','r24',
    'r31','r32','r33','r34',
]

class GlauCUTUStimulusGlowingTest {
    public fixation_x: number[]
    public tests: TestPoint[] = [] 
    public scene: THREE.Scene
    public fixationPoint: VRMesh
    public minGray: number
    public maxGray: number
    public duration: number
    public delay: number
    
    public isStarted = false
    public currentIndex = -1
    public currentTimeout = -1
    public currentTestPoint: StimulusGlowingMesh | undefined

    public testClock = new THREE.Clock()
    private results: any;

    public onStopTest: (()=>void) | undefined
    public onStartTest: (()=>void) | undefined
    public onShowTest: ((test: TestPoint)=>void) | undefined
    public onMiss: ((test: TestPoint)=>void) | undefined
    public onHit: ((test: TestPoint)=>void) | undefined
    public onFalseHit: (()=>void) | undefined

    constructor(scene: THREE.Scene, fixationPoint: VRMesh, minGray=30/255, maxGray=1.0, duration=1200, delay=200, testlist=TestList24_2, filler=0.1, blind=0.1, fixation_shift=8){
        this.fixation_x = [0, fixation_shift, -fixation_shift]
        this.scene = scene
        this.fixationPoint = fixationPoint

        this.minGray = minGray
        this.maxGray = maxGray
        this.duration = duration
        this.delay = delay

        const ntest = testlist.length
        const nfiller = Math.round((filler * ntest)*2/3)
        const nblind = Math.round((blind * ntest)/3)
        
        for (const fx of this.fixation_x){
            const p = new Vector2(90, 90) // no way see
            const b = new Vector2(-15.5, -1.5) // average blind spot

            for (let i = 0; i < nfiller; i++) {
                this.tests.push(new TestPoint(`filler_${i}`, p, "b", fx))
            }
            for (let i = 0; i < nblind; i++) {
                this.tests.push(new TestPoint(`blind_r_${i}`, b, "r", fx))
                this.tests.push(new TestPoint(`blind_l_${i}`, b, "l", fx))
            }
        }

        for(const p of testlist){
            if (p.x > 14) {
                this.tests.push(new TestPoint(`r/${p.x}/${p.y}`, p, "r", this.fixation_x[1]))
                this.tests.push(new TestPoint(`l/${p.x}/${p.y}`, p, "l", this.fixation_x[2]))
            } else if (p.x < -14){
                this.tests.push(new TestPoint(`r/${p.x}/${p.y}`, p, "r", this.fixation_x[2]))
                this.tests.push(new TestPoint(`l/${p.x}/${p.y}`, p, "l", this.fixation_x[1]))
            } else {
                this.tests.push(new TestPoint(`r/${p.x}/${p.y}`, p, "r", this.fixation_x[0]))
                this.tests.push(new TestPoint(`l/${p.x}/${p.y}`, p, "l", this.fixation_x[0]))
            }
        }
        this.tests.sort(TestPoint.random_sort)

        const nlatency = Math.floor(this.tests.length / 10) 
        const ls = [new Vector2(1, 1), new Vector2(1, -1), new Vector2(-1, 1), new Vector2(-1, -1)]
        for(let i=nlatency; i>0; i--){
            const prev = this.tests[i*10-1]
            this.tests.splice(i*10, 0, new TestPoint(`latency${i}`, ls[i%4], "b", prev.fixation_x))
        }
        for(const l of ls){ // 4 First Start
            this.tests.splice(0, 0, new TestPoint(`latency_s`, l, "b", this.fixation_x[0]))
        }

        this.results = {
            'based_response': 0,
            'responses': {},
            'blindspot_click': 0,
            'filler_click': 0,
            'false_click': 0,
            'progress': 0,
        }
    }

    public start() {
        if (this.isStarted){
            console.log("Already start")
            return
        }

        this.currentIndex = -1
        this.isStarted = true
        this.testClock.start()

        this.currentTimeout = setTimeout(()=>this.showTestPoint(), 2000 + Math.random()*1000)
        if (this.onStartTest) {
            this.onStartTest()
        }
    }

    public stop(){
        if (!this.isStarted){
            console.log("Stop without start")
            return
        }
        this.isStarted = false
        this.testClock.stop()
        if (this.currentTestPoint != undefined){
            this.scene.remove(this.currentTestPoint)
        }
        clearTimeout(this.currentTimeout)

        this.calculateResult()

        if (this.onStopTest) {
            this.onStopTest()
        }
    }

    public showTestPoint(){
        this.currentIndex++;
        if (this.currentIndex >= this.tests.length) {
            this.stop()
            return
        }
        
        const {point_angle, fixation_x, mask, size} = this.tests[this.currentIndex]

        if(this.currentTestPoint){
            this.currentTestPoint.stop()
            this.scene.remove(this.currentTestPoint)
        }

        this.currentTestPoint = new StimulusGlowingMesh(point_angle, 5, size, 
            this.minGray, this.maxGray, this.duration, this.delay, mask, ()=>this.miss())
        this.scene.add(this.currentTestPoint)

        clearTimeout(this.currentTimeout) // Should be nothing
        this.currentTimeout = -1

        if (this.fixationPoint.position_angle.x != fixation_x) {
            this.currentTimeout = setTimeout(()=>{
                this._showTestPoint()

                clearTimeout(this.currentTimeout) // Should be nothing
                this.currentTimeout = -1
            }, 500)

            this.fixationPoint.position_angle.x = fixation_x
            this.fixationPoint.updatePosition()
        } else {
            this._showTestPoint()
        }
    }

    private _showTestPoint() {
        if (this.currentTestPoint != undefined){
            this.currentTestPoint.start()
            this.testClock.getDelta()
            
            if (this.onShowTest) {
                this.onShowTest(this.tests[this.currentIndex])
            }
        } else {
            console.error("currentTestPoint is undefined")
        }
    }

    public response(){
        if (!this.isStarted || this.tests[this.currentIndex] == undefined) {
            console.error("Responses without Start or current index is undefined")
            return
        }
        const responseTime = this.testClock.getDelta()
        const {name} = this.tests[this.currentIndex]
        if (this.currentTestPoint) {
            this.tests[this.currentIndex].reactiontime = responseTime
            this.currentTestPoint.stop()
            this.scene.remove(this.currentTestPoint)

            if (name.startsWith("blind")){
                this.results['blindspot_click'] += 1
            } else if (name.startsWith("filler")){ 
                this.results['filler_click'] += 1
            }
            if (this.onHit) {
                this.onHit(this.tests[this.currentIndex])
            }
        }else{
            clearTimeout(this.currentTimeout)
            this.results['false_click'] += 1
            if (this.onFalseHit) {
                this.onFalseHit()
            }
        }
    }

    public next() {
        if (!this.isStarted) {
            console.error("Next without starting")
            return
        }
        clearTimeout(this.currentTimeout)
        // Delay2
        this.currentTimeout = setTimeout(()=>this.showTestPoint(), this.delay+Math.random()*500) // next
    }

    private miss(){
        if (!this.isStarted) {
            console.error("Miss without starting")
            return
        }
        if (this.currentTestPoint){
            this.scene.remove(this.currentTestPoint)
        }
        // const { name } = this.tests[this.currentIndex]
        this.next()
        this.tests[this.currentIndex].reactiontime = -2

        if (this.onMiss) {
            this.onMiss(this.tests[this.currentIndex])
        }
    }

    public getElapsedTime() {
        return this.testClock.getElapsedTime()
    }

    private calculateResult(){
        console.log(this.tests)
        const latency_rt = this.tests.filter(t => t.name.startsWith("latency") && t.reactiontime>0).map(t => t.reactiontime)
        this.results['based_response'] = 0.5 * iqr(latency_rt)['lower_bound']
        if (this.results['based_response'] <= 0) {
            this.results['based_response'] = quantile(latency_rt, 0.25)
        }

        for(const g of basicGroup){
            const rt = this.tests.filter(t=> t.group == g && t.reactiontime>this.results['based_response']).map(t => t.reactiontime)
            this.results['responses'][g] = {
                avg: (rt.length > 0)?mean(rt)-this.results['based_response']:-2,
                std: (rt.length > 1)?std(rt):0
            }
        }
        this.results['progress'] = this.currentIndex/this.tests.length
    }

    public getResults(){
        return this.results
    }
}

export {
    VRMesh,
    StimulusGlowingMesh,
    XRGamepad,
    TestList24_2,
    LEFT_MASK,
    RIGHT_MASK,
    TestPoint,
    GlauCUTUStimulusGlowingTest,
}