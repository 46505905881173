<script setup lang="ts">
import VRGlauCUTU from './components/VRGlauCUTU.vue';

const version = process.env.VUE_APP_VERSION || "dev";
</script>

<template>
  <div class="mb-5">
    <div class="container-md relative">
      <div class="row mt-5 mb-5">
        <div class="col-md-6">
          <img id="logo" src="img/logo.png" width=350 class="img-fluid">
        </div>
        <div class="col-md-6">
          <div class="row">
            <ul class="nav mb-3 justify-content-end">
              <li class="nav-item m-1">
                <a href="#instruction" class="nav-link rounded-3">วิธีการทดสอบ</a>
              </li>
              <li class="nav-item m-1">
                <a href="#about" class="nav-link rounded-3">ข้อมูลเพิ่มเติม</a>
              </li>
            </ul>
          </div>
          <div class="row quotetxt"><h1>"เข้าถึงง่าย รู้ผลไว ใช้ตาได้นาน"</h1></div>
        </div>
      </div>
      <VRGlauCUTU ref="vrMode"></VRGlauCUTU>
      <div class="row justify-content-between promote mb-5">
        <div class="col-md promote mt-3">
          <h3><i>วัดลานสายตาบนโลกเสมือนจริง</i></h3>
          <img id="logo" src="img/ss01-01.png" class="img-fluid">
        </div>
        <div class="col-md promote mt-3">
          <h3><i>รู้ผลไวภายใน 5-8 นาที</i><sup>๒</sup></h3>
          <img id="logo" src="img/ss01-02.png" class="img-fluid">
          <span class="remark"><sup>๒</sup> ระยะเวลาในการทดสอบแปรผันตามประสิทธิภาพการตอบสนองในแต่ละคน</span>
        </div>
        <div class="col-md promote mt-3">
          <h3><i>แปลผลเพื่อคัดกรองโรค</i><sup>๓</sup></h3>
          <img id="logo" src="img/ss01-03.png" class="img-fluid">
          <span class="remark"><sup>๓</sup> ทำการทดสอบในโหมดพบผู้เชี่ยวชาญเท่านั้น ระบบจะทำการแปลผลและคัดกรองโดยใช้ AI</span>
        </div>
      </div>
      
      <div class="row justify-content-center">
        <div class="col-md instruction rounded-3 p-4" id="instruction">
          <h3><strong>จอยควบคุมการทำงาน</strong></h3>
          <img src="img/joycontroller.png" class="img-fluid mb-3">
          <div><strong>ปุ่ม Thumbstick</strong> - กดลงเพื่อเริ่มต้นการทำสอบหลังจากเข้าสู่โหมด VR </div>
          <div><strong>ปุ่ม A / B / Select</strong> - กดลงเพื่อตอบสนองกับจุดกระตุ้นเมื่อมองเห็น (หรือคิดว่ามองเห็น) ให้ไวที่สุดเท่าที่จะทำได้ จะกดปุ่มใดก็ได้ที่ถนัดที่สุด </div>
          <!-- <router-view/> -->

          <h3 class="mt-5"><strong>ขั้นตอนการทดสอบ</strong></h3>
          <div class="row">
            <div class="col-md-6 mb-3">
              <img src="img/step1.png" class="img-fluid mb-3">
              <div><strong>1. เข้าสู่โหมด VR</strong> - เมื่อเข้าสู่โหมด VR จะมีการเตรียมความพร้อมก่อนเริ่มทดสอบ โดยให้มองที่จุดสีเขียวตลอดเวลาจนกว่าจะทดสอบเสร็จสิ้น ในหน้าจอนี้โดยปกติ<b>จะมองไม่เห็นจุดสีแดง หรือมองเห็นจุดแดงกระพิบ</b> หากมองเห็นปกติกรุณาแจ้งต่อผู้เชียวชาญ หากพร้อมสามารถกดเริ่มโดยการกดปุ่ม <b>Thumbstick</b> ลงด้วยจอยควบคุมข้างที่ถนัด</div>
            </div>
            <div class="col-md-6 mb-3">
              <img src="img/step2.png" class="img-fluid mb-3">
              <div><strong>2. เริ่มต้นทดสอบ</strong> - เมื่อเริ่มทดสอบระบบจะมีเสียงซ่าเพื่อให้ผู้ทดสอบมีสมาธิมากขึ้น ผู้ทดสอบต้องมองจุดเขียวตลอดเวลา (จุดเขียวมีการเปลี่ยนที่ได้) เมื่อปรากฏจุดกระตุ้น (จุดสีขาว) ให้ผู้ทดสอบกดปุ่ม <strong>A หรือ B หรือ Select</strong> ให้ไวที่สุดเท่าที่จะทำได้ จากนั้นรอจนกว่าจุดกระตุ้นถัดไปจะปรากฏ ทำซ้ำจนกว่าจะจบการทดสอบ </div>
            </div>
            <div class="col-md-6 mb-3">
              <img src="img/ss01-02.png" class="img-fluid mb-3">
              <div><strong>3. ผลการทดสอบ</strong> - เมื่อทดสอบเสร็จ โปรแกรมจะออกจากโหมด VR อย่างอัตโนมัติและคำนวนผลการทดสอบต่อไป ทั้งนี้โปรแกรมจะแสดงเฉพาะ<strong>ผลการทดสอบเบื้องต้นเท่านั้น</strong> หากท่านเลือกโหมดแปลผลโดยผู้เชี่ยวชาญผลลัพธ์จะถูกส่งไปยังผู้เชี่ยวชาญอย่างอัตโนมัติต่อไป</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer class="footer mt-auto py-3 " id="about">
    <div class="container-md">
      <div class="row justify-content-between mt-4 mb-5">
        <div class="col-md-6">
          <h5 class="highlight">GlauCUTU: Eye Tester WebXR Application</h5>
          เว็บแอพตรวจตาบนโลกเสมือนจริง ให้คนไทยใช้ฟรี<br/>
          <br/>
          ภายใต้ความร่วมมือระหว่าง <strong class="highlight inline-block">คณะแพทย์ศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย</strong><br/>
          และ <strong class="highlight inline-block">คณะวิศวกรรมศาสตร์ มหาวิทยาลัยธรรมศาสตร์</strong><br/>
          <br/>
          สอบถามข้อมูลเพิ่มเติม <a href="mailto:contact@glaucutu.com">contact@glaucutu.com</a><br/>
          <br/>
          <span class="build-number">Version: {{ version }}</span>
        </div>
        <div class="col-md-6">
          <strong class="highlight">เอกสารอ้างอิง</strong>
          <div class="citation">
            Kunumpol, P., Lerthirunvibul, N., Phienphanich, P., Munthuli, A., Temahivong, K., Tantisevi, V., Manassakorn, A., Chansangpetch, S., Itthipanichpong, R., Ratanawongphaibul, K. and Rojanapongpun, P. (2022). GlauCUTU: Time Until Perceived Virtual Reality Perimetry With Humphrey Field Analyzer Prediction-Based Artificial Intelligence. <i>IEEE Access, 10</i>, 36949-36962.
          </div>
          <div class="citation">
            Kunumpol, P., Lerthirunvibul, N., Phienphanich, P., Munthuli, A., Tantisevi, V., Manassakorn, A., Chansangpetch, S., Itthipanichpong, R., Ratanawongphaibol, K., Rojanapongpun, P. and Tantibundhit, C. (2021, November). GlauCUTU: Virtual reality visual field test. In <i>2021 43rd Annual International Conference of the IEEE Engineering in Medicine & Biology Society (EMBC)</i> (pp. 7416-7421). IEEE.
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style>

@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,400;0,700;1,400;1,700&display=swap');
/* @import "../node_modules/bootstrap/scss/bootstrap"; */

* {
  font-family: 'Kanit', sans-serif;
}

html {
  scroll-behavior:smooth;
}

body {
  background-color: rgb(36,24,28)!important;
  background-image: url(../public/img/bg-inspiration-geometry.png), linear-gradient(90deg, rgba(61,10,2,1) 0%, rgba(36,24,28,1) 100%);
}

.relative {
  position: relative;
}

.build-number{
  color: #333333;
  font-size: 0.75rem;
}

.quotetxt{
  font-style: italic;
  vertical-align: middle;
  text-align: right;
  color: #fcfcfc;
}

.promote {
  color: #fcfcfc;
  text-align: center;
}

.nav-link {
  color: #f0f0f0!important;
  background-color: rgba(139,0,0,0.8);
}

.nav-link:hover{
  color: darkred!important;
  background-color: #f0f0f0!important;
}

.content {
  color: darkred;
  background-color: #f0f0f0;
}

.content-dark {
  background-color: darkred;
  color: rgb(240, 240, 240);
}

.communicate-code {
  font-size: 4rem;
  text-align: center;
}

.remark {
  font-size: 0.85rem;
  font-style: italic;
  font-weight: lighter;
}

.img-center-bottom{
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 0;
}

.pb-more {
  color: black;
  padding-bottom: 155px!important;
}

.bg-vr-head{
  background-image: linear-gradient(90deg, rgba(240,240,240,0.45) 0%, rgba(240,240,240,0.45) 100%), url(../public/img/vr-head.png);
  background-position-y: bottom;
  background-position-x: right;
  background-repeat: no-repeat;
  background-size: contain;
}

.warning{
  font-size: 2rem;
  background-color: darkred;
  color: white;
}


.bg-vr-head .detail {
  background-color: rgba(240,240,240, 0.9);
}

.btn-enter-vr{
  z-index: 999;
  position: absolute;
  left: 10%;
  bottom: 0;
}

.instruction {
  color: darkred;
  background-color: #f0f0f0;
}

.citation {
  font-size: 0.95rem;
  margin-bottom: 5px;
}

html {
  position: relative;
  min-height: 100%;
}

footer{
  width: 100%;
  background-color: #ccc;
  color: #1e1e1e;
}

footer .highlight {
  color:  rgba(61,10,2,1);
}

.inline-block{
  display: inline-block!important;
}

</style>