<script setup lang="ts">
import { defineExpose, ref } from 'vue';

import { io, Socket } from 'socket.io-client';

interface AgentInfo {
  name?: string,
  image?: string,
  position?: string,
  affiliation?: string,
}

const roomid = ref("-")
const agentinfo = ref({} as AgentInfo)
const doctorview = ref<HTMLVideoElement | null>(null);

let socket_options = {
  extraHeaders: {
      glaucutu: "",
  },
}

let socket: Socket
let canvas: HTMLCanvasElement

let pc: RTCPeerConnection
const constraints = {
    audio: true,
    video: false, 
}

// eslint-disable-next-line
function createPeerConnection(configuration: RTCConfiguration | undefined){
  const pc = new RTCPeerConnection(configuration); 

  pc.onicecandidate = ({candidate}) => socket.emit("webrtcevent", {
    candidate: candidate,
  })

  pc.onnegotiationneeded = async () => {
    try {
      await pc.setLocalDescription(await pc.createOffer());
      socket.emit("webrtcevent", {
        desc: pc.localDescription,
      })
    } catch (err){
      console.error(err)
    }
  }
  
  pc.ontrack = (event) => {
    if (doctorview.value != undefined){
      doctorview.value.srcObject = event.streams[0];
      doctorview.value.onloadedmetadata = () => {
          doctorview.value?.play();
      }
      //answer
      document.getElementById("doctorconnect")?.scrollIntoView({
        behavior: 'smooth', // smooth scroll
        block: 'start' // the upper border of the element will be aligned at the top of the visible part of the window of the scrollable area.
      })
    }
  }

  return pc
}

function setCanvasReference(c: HTMLCanvasElement) {
    canvas = c
    // console.log(canvas)
}

function connectWebSocket() {
    socket = io("wss://wss.glaucutu.com/client", socket_options)
    socket.on("join-room", (rid: string)=>{
      if (socket.io.opts.extraHeaders == undefined){
        socket.io.opts.extraHeaders = {}
      }
      socket.io.opts.extraHeaders['glaucutu'] = rid
      roomid.value = rid;
    })

    socket.on("agent-connected", (base64String) => {
        const agent = JSON.parse(decodeURIComponent(escape(atob(base64String))))
        // console.log("agent", agent)
        agentinfo.value = agent
    })

    socket.on("agent-disconnected", () => {
        agentinfo.value = {}
    })
    // console.log(canvas)

    socket.on("webrtcevent", async ({desc, candidate, configuration})=>{
    try {
      if (desc) {
        if (!pc){
          pc = createPeerConnection(undefined)
        }
        // If you get an offer, you need to reply with an answer.
        if (desc.type === 'offer') {
          await pc.setRemoteDescription(desc);
          const stream = await window.navigator.mediaDevices.getUserMedia(constraints);
          stream.getTracks().forEach((track) => pc.addTrack(track, stream));
          const canvasStream = canvas.captureStream(25); // 25 FPS
          canvasStream.getTracks().forEach((track) => pc.addTrack(track, stream));
          await pc.setLocalDescription(await pc.createAnswer());
          socket.emit("webrtcevent", {desc: pc.localDescription})
        } else if (desc.type === 'answer') {
          await pc.setRemoteDescription(desc);
        } else {
          console.log('Unsupported SDP type.');
        }
      } else if (candidate) {
        if (!pc){
          pc = createPeerConnection(undefined)
        }
        await pc.addIceCandidate(candidate);
      } else if (configuration){
        pc = createPeerConnection(configuration)
      }
    } catch (err) {
      console.error(err);
    }
  })

    return socket
}

defineExpose({
    roomid,
    connectWebSocket,
    setCanvasReference,
})
</script>

<template>
    <h3 id="doctorconnect"><strong>แปลผลโดยผู้เชี่ยวชาญ</strong></h3>
    <div v-if="!('name' in agentinfo)">
        <div class="communicate-code">{{ roomid }}</div>

        แจ้งตัวเลขนี้กับเจ้าหน้าที่ของโรงพยาบาลที่รองรับ <strong>GlauCUTU</strong> เพื่อให้ผู้เชี่ยวชาญร่วมทดสอบกับท่าน 
        ผลการทดสอบจะถูกส่งไปแปลผลและเชื่อมโยงกับข้อมูลของท่านที่โรงพยาบาลอย่างอัตโนมัติ<sup>๑</sup> 
        เพื่อให้แพทย์สามารถประกอบการวินิจฉัยต่อไป

        <div class="mt-3 remark"><sup>๑</sup> เมื่อเข้าสู่โหมดนี้จะถือว่าท่านยินยอมเปิดเผยข้อมูลการทดสอบกับเจ้าหน้าที่/โรงพยาบาลที่ท่านมอบหมายเลขข้างต้นให้โดยปริยาย</div>
    </div>
    <div v-else class="relative">
        <h4 class="mt-3">ผู้เชี่ยวชาญที่กำลังเชื่อมต่อ</h4>
        <video id="doctorview" class="rounded-3" ref="doctorview"></video>
        <div id="doctordetail" class="row align-items-center rounded-3 pt-3 pb-3">
            <div class="col-sm-auto">
                <img class="img-thumbnail rounded-circle" :src=agentinfo.image width="100">
            </div>
            <div class="col-md-auto">
                <strong>{{ agentinfo.name }}</strong><br />
                ตำแหน่ง: {{ agentinfo.position }}<br />
                สังกัด: {{ agentinfo.affiliation }}<br />
            </div>
        </div>
    </div>
</template>

<style scoped>
#doctorview {
    background-color: darkgray;
    z-index: 999;
    width: 400px;
}

#doctordetail {
  position: absolute;
  bottom: 6px;
  left: 12px;
  background-color: rgba(0,0,0,0.5);
  width: 400px;
}
</style>