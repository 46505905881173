import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/css/bootstrap-utilities.min.css"
import "bootstrap/dist/css/bootstrap-grid.min.css"

const app = createApp(App)

Sentry.init({
    app,
    dsn: "https://3949be0a000f4329a6403750e55da296@o4504670904057856.ingest.sentry.io/4504670919262208",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ["localhost", "app.glaucutu.com", "glaucutu.web.app", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

app.use(router)
app.mount('#app')

import "bootstrap/dist/js/bootstrap.bundle.js"

  